// external imports
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  collection,
  getDocs,
  doc,
  onSnapshot,
  Timestamp,
} from "firebase/firestore";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import {
  PencilSquareIcon as PencilSquareSolidIcon,
  TrashIcon as TrashSolidIcon,
  PencilIcon as PencilSolidIcon,
} from "@heroicons/react/24/solid";

// internal imports
import { db } from "../../../firebase/firebase.config";
import { getDuration } from "../../../utils/time";
import { SpeciesProps } from "../../productGroups/interfaces";
import { MenuOption } from "../../../components/types";
import DeleteForm from "../../../components/forms/DeleteForm";
import BackNavigableResourceHeader from "../../../components/headers/BackNavigableResourceHeader";
import EditStumpForm from "./EditStumpForm";
import PageWrapper from "../../../components/wrappers/PageWrapper";
import ProductAndStumpList from "../../../components/lists/ProductAndStumpList";
import Notes from "../../../components/notes/Notes";
import { Stump as StumpModel } from "../../../db/models";

function Stump() {
  const { stumpId, tallyId } = useParams();
  const [stump, setStump] = useState<StumpModel>();
  const [species, setSpecies] = useState<SpeciesProps[]>([]);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteFormOpen, setIsDeleteFormOpen] = useState(false);

  const navigate = useNavigate();

  //  fetch stump from firestore
  useEffect(() => {
    if (stumpId) {
      const fetchStump = () => {
        const stumpRef = doc(db, "stumps", stumpId);
        const unsubscribe = onSnapshot(stumpRef, (stumpDoc) => {
          if (stumpDoc.exists()) {
            const stumpData: StumpModel = {
              id: stumpDoc.id,
              ...(stumpDoc.data() as StumpModel),
            };

            setStump(stumpData);
          } else {
            console.log("Stump not found");
          }
        });

        return unsubscribe;
      };

      return fetchStump();
    }
  }, [stumpId]);

  console.log("stump:", stump);

  // Fetch the species data from Firestore
  useEffect(() => {
    const fetchSpecies = async () => {
      const speciesRef = collection(db, "species");
      const speciesSnapshot = await getDocs(speciesRef);
      const speciesData = speciesSnapshot.docs.map((doc) => ({
        ...(doc.data() as SpeciesProps),
        id: doc.id,
      }));
      setSpecies(speciesData);
    };
    fetchSpecies();
  }, []);

  // Get the matching species based on the speciesList numbers in the product
  const matchingSpecies = species.filter((item) =>
    stump?.speciesId?.includes(item.id),
  );

  // Extract the commonNames from the matching species
  const commonNames = matchingSpecies.map((item) => item.commonName);

  const items: MenuOption[] = [
    {
      text: "Edit",
      Icon: PencilSquareIcon,
      IconHovered: PencilSquareSolidIcon,
      action: () => setIsEditFormOpen(true),
    },
    {
      text: "Delete",
      Icon: TrashIcon,
      IconHovered: TrashSolidIcon,
      action: () => setIsDeleteFormOpen(true),
    },
  ];
  return (
    <>
      <PageWrapper>
        {/* header */}
        <BackNavigableResourceHeader
          title={stump?.name as string}
          onBackClick={() => navigate(`/tallies/${tallyId}`)}
          menuItems={items}
        />

        {/* created / updated section */}
        {stump && (
          <div className="mt-4 flex flex-col items-start justify-center font-light text-slate-500">
            <p>Created: {getDuration(stump.createdAt)}</p>
            <p>Updated: {getDuration(stump.updatedAt)}</p>
          </div>
        )}

        {/* notes section */}
        {stump?.description && <Notes description={stump?.description} />}

        {/* General section */}
        <ProductAndStumpList
          title=""
          dataPairs={[
            { label: "Species", value: commonNames[0] },
            { label: "Stump diameter", value: `${stump?.stumpDiameter}"` },
            {
              label: "Stump height",
              value: `${stump?.stumpHeight}"`,
            },
            {
              label: "Diameter at breast height",
              value: `${stump?.diameterBreastHeight}"`,
            },
            {
              label: "Volume (Doyle)",
              value: `${stump?.volume} BF`,
            },
          ]}
        />
      </PageWrapper>

      {isEditFormOpen && stump && (
        <EditStumpForm
          initialData={stump}
          id={stump?.id as string}
          isEditFormOpen={isEditFormOpen}
          setIsEditFormOpen={setIsEditFormOpen}
        />
      )}
      {/* delete stump form */}
      {isDeleteFormOpen && stump && (
        <DeleteForm
          data={stump}
          id={stump?.id as string}
          collectionName="stumps"
          isDeleteFormOpen={isDeleteFormOpen}
          setIsDeleteFormOpen={setIsDeleteFormOpen}
          navigateAfterDelete={`/tallies/${tallyId}`}
        />
      )}
    </>
  );
}

export default Stump;
