import { Route, Routes } from "react-router";
import ReportsListPage from "../pages/reports/ReportsListPage";
import ReportMenu from "../pages/reports/ReportMenu";
import ReportPage from "../pages/reports/ReportPage";
import NewReportPage from "../pages/reports/NewReportPage";

import { useMediaQuery } from "react-responsive";
import LogPage from "../pages/reports/LogPage";

export default function ReportsLayout() {
  const isMobile = useMediaQuery({ maxWidth: 640 });
  const isTablet = useMediaQuery({ minWidth: 641, maxWidth: 1024 });

  let display;

  if (isMobile) {
    display = (
      <Routes>
        <Route path="/" element={<ReportsListPage />} />
        <Route path="new" element={<NewReportPage />} />
        <Route path=":reportId/*" element={<ReportMenu />} />
        <Route path=":reportId/:by" element={<ReportPage />} />
      </Routes>
    );
  } else if (isTablet) {
    display = (
      <div className="flex w-full overflow-x-hidden mr-1 overflow-y-hidden">
        <div className="w-1/2 mb-2">
          <Routes>
            <Route path="/" element={<ReportsListPage />} />
            <Route path=":reportId" element={<ReportsListPage />} />
            <Route path=":reportId/:by" element={<ReportMenu />} />
          </Routes>
        </div>
        <div className="w-1/2 mx-1 mb-2">
          <Routes>
            <Route path="/" element={<div />} />
            <Route path="new" element={<NewReportPage />} />
            <Route path=":reportId/*" element={<ReportMenu />} />
            <Route path=":reportId/:by" element={<ReportPage />} />
          </Routes>
        </div>
      </div>
    );
  } else {
    display = (
      <div className="flex w-full overflow-x-hidden mx-1 my-1">
        <div className="md:w-1/3">
          <ReportsListPage />
        </div>

        <div className="md:w-1/3 mx-1">
          <Routes>
            <Route path="/" element={<div />} />
            <Route path="new" element={<NewReportPage />} />
            <Route path=":reportId/*" element={<ReportMenu />} />
          </Routes>
        </div>

        <div className="md:w-1/3 ">
          <Routes>
            <Route path="/" element={<div />} />
            <Route path=":reportId/:by" element={<ReportPage />} />
          </Routes>
        </div>
      </div>
    );
  }

  return display;
}
