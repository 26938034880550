// external imports
import { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { collection, query, where, doc, onSnapshot } from "firebase/firestore";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import {
  PencilSquareIcon as PencilSquareSolidIcon,
  TrashIcon as TrashSolidIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/solid";

// internal imports
import { NewProductGroupProps } from "./interfaces";
import { NewProductProps } from "./interfaces";
import { ProductGroupIndividualProps } from "./interfaces";
import { db } from "../../firebase/firebase.config";
import { Product } from "./interfaces";
import { getDuration } from "../../utils/time";
import { MenuOption } from "../../components/types";
import FilteredListComponent from "../../components/lists/FilteredList";
import MobileNewHeader from "../../components/headers/MobileNewHeader";
import DeleteForm from "../../components/forms/DeleteForm";
import BackNavigableResourceHeader from "../../components/headers/BackNavigableResourceHeader";
import PageWrapper from "../../components/wrappers/PageWrapper";
import Notes from "../../components/notes/Notes";
import EditForm from "../../components/forms/EditForm";

function ProductGroup() {
  const { productGroupId } = useParams<{ productGroupId: string }>();
  const [productGroup, setProductGroup] =
    useState<ProductGroupIndividualProps | null>();
  const [products, setProducts] = useState<Product[]>([]);
  const [filteredProducts, setFilteredProducts] = useState("");
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteFormOpen, setIsDeleteFormOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!productGroupId) return;

    const productGroupRef = doc(db, "productGroups", productGroupId);

    const unsubscribe = onSnapshot(productGroupRef, (productGroupDoc) => {
      if (productGroupDoc.exists()) {
        const productGroupData: ProductGroupIndividualProps = {
          id: productGroupDoc.id,
          data: productGroupDoc.data() as NewProductGroupProps,
          createdAt: productGroupDoc.data().createdAt,
          updatedAt: productGroupDoc.data().updatedAt,
        };
        setProductGroup(productGroupData);
      } else {
        console.log("Product group not found");
      }
    });

    return () => unsubscribe();
  }, [productGroupId]);

  // Fetch products and subscribe to real time updates
  useEffect(() => {
    if (!productGroupId) return;
    const productsCollectionRef = collection(db, "products");
    const productsQuery = query(
      productsCollectionRef,
      where("productGroupId", "==", productGroupId),
    );

    const unsubscribe = onSnapshot(productsQuery, (snapshot) => {
      const products = snapshot.docs.map((doc) => {
        const subtitleLength = doc.data().price;
        const subtitleText = "/mbf";
        return {
          id: doc.id,
          data: doc.data() as NewProductProps,
          subtitle: `$${subtitleLength}${subtitleText}`,
        };
      });
      setProducts(products);
    });

    // Clean up the subscription when component unmounts or when productGroupId changes
    return () => unsubscribe();
  }, [productGroupId]);

  // filter products
  const filteredProduct = products?.filter((product) => {
    return product?.data?.name
      .toLowerCase()
      .includes(filteredProducts.toLowerCase());
  });

  const items: MenuOption[] = [
    {
      text: "Edit",
      Icon: PencilSquareIcon,
      IconHovered: PencilSquareSolidIcon,
      action: () => setIsEditFormOpen(true),
    },
    {
      text: "Delete",
      Icon: TrashIcon,
      IconHovered: TrashSolidIcon,
      action: () => setIsDeleteFormOpen(true),
    },
  ];

  console.log("the products", products);

  console.log("product group", productGroup);

  return (
    <>
      <PageWrapper>
        {/* header */}
        <BackNavigableResourceHeader
          title={productGroup?.data.name as string}
          onBackClick={() => navigate("/productGroups")}
          menuItems={items}
        />

        {/* created / updated section */}
        {productGroup && (
          <div className="mt-4 flex flex-col items-start justify-center font-light text-slate-500">
            <p>Created: {getDuration(productGroup.createdAt)}</p>
            <p>Updated: {getDuration(productGroup.updatedAt)}</p>
          </div>
        )}
        {/* notes section */}
        {productGroup?.data.description && (
          <Notes description={productGroup?.data.description} />
        )}

        {/* Products section */}
        <div className="mt-8 flex flex-row items-center justify-between">
          <h2 className="text-xl font-bold text-slate-700">Products</h2>
          <Link to={`/productGroups/${productGroupId}/products/new`}>
            <PlusCircleIcon className="hidden h-10 w-10 text-yellow-500 md:flex" />
          </Link>
        </div>
        {/* filter products */}
        <FilteredListComponent
          filterValue={filteredProducts}
          getIsActivePath={(product) =>
            location.pathname.startsWith(
              `/productGroups/${productGroup?.id}/products/${product.id}`,
            )
          }
          items={filteredProduct.sort(
            (a, b) => b.data.createdAt.seconds - a.data.createdAt.seconds,
          )}
          onFilterChange={(e) => setFilteredProducts(e.target.value)}
          onItemSelect={(product) =>
            navigate(
              `/productGroups/${productGroup?.id}/products/${product.id}`,
            )
          }
          placeholder="Filter products"
        />
        {/* Mobile new product button */}
        <MobileNewHeader
          newUrl={`/productGroups/${productGroupId}/products/new`}
        />
      </PageWrapper>

      {/* Edit productGroup form */}
      {isEditFormOpen && productGroup && (
        <EditForm
          data={productGroup?.data}
          id={productGroup?.id as string}
          isEditFormOpen={isEditFormOpen}
          setIsEditFormOpen={setIsEditFormOpen}
          collectionName="productGroups"
        />
      )}

      {/* Delete productGroup form */}
      {isDeleteFormOpen && productGroup && (
        <DeleteForm
          data={productGroup?.data}
          id={productGroup?.id as string}
          collectionName="productGroups"
          isDeleteFormOpen={isDeleteFormOpen}
          setIsDeleteFormOpen={setIsDeleteFormOpen}
          navigateAfterDelete={"/productGroups"}
        />
      )}
    </>
  );
}

export default ProductGroup;
