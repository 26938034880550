// External imports
import { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router";
import {
  PlusIcon,
  MinusIcon,
  PencilSquareIcon,
  TrashIcon,
  DocumentArrowDownIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import {
  PencilSquareIcon as PencilSquareSolidIcon,
  TrashIcon as TrashSolidIcon,
  DocumentArrowDownIcon as DocumentArrowDownSolidIcon,
} from "@heroicons/react/24/solid";
import { Disclosure } from "@headlessui/react";

// Internal imports
import BackNavigableResourceHeader from "../../components/headers/BackNavigableResourceHeader";
import { Report, Product, Stump, ProductGroup, Tally } from "../../db/models";
import { getDuration } from "../../utils/time";
import {
  fetchAllDocumentsFromCollection,
  fetchDocumentById,
  fetchDocumentsByQuery,
} from "../../db/fetchers";
import EditForm from "../../components/forms/EditForm";
import DeleteForm from "../../components/forms/DeleteForm";

export default function ReportMenu() {
  // const [isLoading, setIsLoading] = useState(true);
  const [productGroup, setProductGroup] = useState<ProductGroup | null>(null);
  const [tally, setTally] = useState<Tally | null>(null);
  const [report, setReport] = useState<Report>();
  const [isDeleteFormOpen, setIsDeleteFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  // Extract the report id from the URL
  const { reportId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // This effect fetches the report and then all products and stumps in parallel.
  // It then groups the logs by product, species, and stump and stores them in the data state and
  // computes the revenue sums for each group.
  useEffect(() => {
    async function fetchData() {
      const report = await fetchDocumentById<Report>("reports", reportId);

      if (!report) throw new Error("Report not found");
      setReport(report);

      const [productGroup, tally] = await Promise.all([
        fetchDocumentById<ProductGroup>("productGroups", report.productGroupId),
        fetchDocumentById<Tally>("tallies", report.tallyId),
      ]);
      setProductGroup(productGroup);
      setTally(tally);
    }
    fetchData();
  }, [reportId]);

  const menuItems = [
    {
      text: "Edit",
      Icon: PencilSquareIcon,
      IconHovered: PencilSquareSolidIcon,
      action: () => {
        setIsEditFormOpen(true);
      },
    },
    {
      text: "Delete",
      Icon: TrashIcon,
      IconHovered: TrashSolidIcon,
      action: () => {
        setIsDeleteFormOpen(true);
      },
    },
  ];

  // if (isLoading || !report || !tally || !productGroup) return null;
  if (!report || !tally || !productGroup) return null;
  return (
    <>
      <div className="h-full max-h-[50px] min-h-full w-full m-1 lg:m-0  ">
        <div className=" flex h-full w-full flex-col overflow-y-auto rounded-lg border border-gray-300 bg-white p-4 ">
          {/* header */}
          <BackNavigableResourceHeader
            title={report.name}
            onBackClick={() => navigate("/reports")}
            menuItems={menuItems}
          />
          <div className="flex flex-col py-2 text-sm text-slate-500 mt-2">
            <div>Created {getDuration(report.createdAt)}</div>
            <div>Product group: {productGroup.name}</div>
            <div>Tally: {tally.name}</div>
          </div>

          {/* By species */}
          <div
            className={`cursor-pointer flex flex-row border items-center justify-between rounded-md px-2 py-3 hover:bg-slate-50 ${
              location.pathname.split("/").includes("species")
                ? "bg-slate-50 border-slate-100"
                : "border-white"
            }`}
            onClick={() => navigate(`/reports/${reportId}/species`)}
          >
            <div className="flex flex-col">
              <h3 className="text-left text-lg font-semibold text-slate-700">
                By species
              </h3>
            </div>

            <ChevronRightIcon className="h-5 w-5 text-slate-500" />
          </div>

          {/* By product */}
          <div
            className={`cursor-pointer flex flex-row border items-center justify-between rounded-md px-2 py-3 hover:bg-slate-50 ${
              location.pathname.split("/").includes("species")
                ? "bg-slate-50 border-slate-100"
                : "border-white"
            }`}
            onClick={() => navigate(`/reports/${reportId}/product`)}
          >
            <div className="flex flex-col">
              <h3 className="text-left text-lg font-semibold text-slate-700">
                By product
              </h3>
            </div>

            <ChevronRightIcon className="h-5 w-5 text-slate-500" />
          </div>

          {/* By stump */}
          <div
            className={`cursor-pointer flex flex-row border items-center justify-between rounded-md px-2 py-3 hover:bg-slate-50 ${
              location.pathname.split("/").includes("species")
                ? "bg-slate-50 border-slate-100"
                : "border-white"
            }`}
            onClick={() => navigate(`/reports/${reportId}/stump`)}
          >
            <div className="flex flex-col">
              <h3 className="text-left text-lg font-semibold text-slate-700">
                By stump
              </h3>
            </div>

            <ChevronRightIcon className="h-5 w-5 text-slate-500" />
          </div>
        </div>
      </div>

      {isEditFormOpen && (
        <EditForm
          data={report}
          id={reportId as string}
          collectionName="reports"
          isEditFormOpen={isEditFormOpen}
          setIsEditFormOpen={setIsEditFormOpen}
        />
      )}

      {isDeleteFormOpen && (
        <DeleteForm
          data={report}
          id={reportId as string}
          collectionName="reports"
          isDeleteFormOpen={isDeleteFormOpen}
          setIsDeleteFormOpen={setIsDeleteFormOpen}
          navigateAfterDelete="/reports"
        />
      )}
    </>
  );
}
