import {
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase/firebase.config";

export type FieldRename = {
  rename: string;
  to: string;
};

export async function fetchAllDocumentsFromCollection<T>(
  collectionName: string,
  includeFields?: string[],
  renames?: FieldRename[],
): Promise<T[]> {
  const collectionRef = collection(db, collectionName);
  const collectionSnapshot = await getDocs(collectionRef);

  const collectionData = collectionSnapshot.docs.map((doc) => {
    let docData = doc.data();

    // Filtering the fields if includeFields is provided
    if (includeFields) {
      docData = Object.fromEntries(
        Object.entries(docData).filter(([key]) => includeFields.includes(key)),
      );
    }

    // Renaming fields
    if (renames) {
      renames.forEach(({ rename, to }) => {
        if (docData[rename] !== undefined) {
          docData[to] = docData[rename];
          delete docData[rename];
        }
      });
    }

    return {
      id: doc.id,
      ...docData,
    };
  }) as T[];

  return collectionData;
}

export async function fetchDocumentById<T>(
  collectionName: string,
  docId: string | undefined,
  includeFields?: string[],
  renames?: FieldRename[],
): Promise<T | null> {
  if (docId === undefined) {
    return null;
  }
  const docRef = doc(db, collectionName, docId);
  const docSnapshot = await getDoc(docRef);

  if (!docSnapshot.exists) {
    return null;
  }

  let docData: any = docSnapshot.data();

  // Handle field inclusion if specified
  if (includeFields) {
    docData = includeFields.reduce((acc: any, field: string) => {
      if (docData[field] !== undefined) {
        acc[field] = docData[field];
      }
      return acc;
    }, {});
  }

  // Handle field renaming if specified
  if (renames) {
    renames.forEach(({ rename, to }) => {
      if (docData[rename] !== undefined) {
        docData[to] = docData[rename];
        delete docData[rename];
      }
    });
  }

  return docData as T;
}

export async function fetchDocumentsByQuery<T>(
  collectionName: string,
  fieldName: string,
  fieldValue?: string,
  includeFields?: string[],
  renames?: FieldRename[],
): Promise<T[]> {
  if (!fieldValue) return [];

  const dataQuery = query(
    collection(db, collectionName),
    where(fieldName, "==", fieldValue),
  );

  const querySnapshot = await getDocs(dataQuery);

  const documents = querySnapshot.docs.map((doc) => {
    const data = doc.data();
    let selectedData = { ...data };

    // If includeFields is specified, only pick those fields
    if (includeFields) {
      selectedData = includeFields.reduce((acc, field) => {
        if (data[field] !== undefined) {
          acc[field] = data[field];
        }
        return acc;
      }, {} as any);
    }

    // Process renames
    if (renames) {
      renames.forEach((r) => {
        if (selectedData[r.rename] !== undefined) {
          selectedData[r.to] = selectedData[r.rename];
          delete selectedData[r.to];
        }
      });
    }

    return {
      id: doc.id,
      ...selectedData,
    };
  }) as T[];

  return documents;
}
