import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BackNavigableResourceHeader from "../../components/headers/BackNavigableResourceHeader";
import { Product, Tally, Stump, ProductGroup, Report } from "../../db/models";
import {
  fetchAllDocumentsFromCollection,
  fetchDocumentById,
  fetchDocumentsByQuery,
} from "../../db/fetchers";

export default function ReportPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [stumps, setStumps] = useState<Stump[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [productGroup, setProductGroup] = useState<ProductGroup | null>(null);
  const [tally, setTally] = useState<Tally | null>(null);
  const [summaryByStump, setSummaryByStump] = useState<{
    [stumpId: string]: Stump & {
      value: number;
      productId: string;
      productName: string;
      productPrice: number;
      productLogRule: string;
    };
  }>({});

  const { reportId, by } = useParams();
  const navigate = useNavigate();

  function summarizeByStump(stumps: Stump[], products: Product[]) {
    const summary: {
      [stumpId: string]: Stump & {
        value: number;
        productId: string;
        productName: string;
        productPrice: number;
        productLogRule: string;
      };
    } = {};

    stumps.forEach((stump) => {
      for (const product of products) {
        if (product.speciesList.includes(stump.speciesId)) {
          if (stump.id && product.id) {
            if (!summary[stump.id]) {
              summary[stump.id] = {
                ...stump,
                value: parseFloat(
                  ((stump.volume / 1000.0) * product.price).toFixed(2),
                ),
                productId: product.id,
                productName: product.name,
                productPrice: product.price,
                productLogRule: product.logRule,
              };
            }
          }
          break; // Stop after the first matching product to avoid double counting
        }
      }
    });
    return summary;
  }

  function summarizeByCategory(
    summary: {
      [stumpId: string]: Stump & {
        value: number;
        productId: string;
        productName: string;
        productPrice: number;
        productLogRule: string;
      };
    },
    category: "species" | "product" | "stump",
  ) {
    const categorySummary: { [key: string]: number } = {};

    Object.values(summary).forEach((item) => {
      let key: string;
      if (category === "species") {
        if (!item.speciesId) {
          return;
        }
        key = item.speciesId;
      } else if (category === "product") {
        if (!item.productId) {
          return;
        }
        key = item.productId;
      } else {
        if (!item.id) {
          return;
        }
        key = item.id;
      }

      if (!categorySummary[key]) {
        categorySummary[key] = 0;
      }
      categorySummary[key] += item.value;
    });

    return categorySummary;
  }

  // This effect fetches the report and then all products and stumps in parallel.
  // It then groups the logs by product, species, and stump and stores them in the data state and
  // computes the revenue sums for each group.
  useEffect(() => {
    async function fetchData() {
      try {
        const report = await fetchDocumentById<Report>("reports", reportId);

        if (!report) throw new Error("Report not found");

        const [products, stumps] = await Promise.all([
          fetchDocumentsByQuery<Product>(
            "products",
            "productGroupId",
            report.productGroupId,
          ),
          fetchDocumentsByQuery<Stump>("stumps", "tallyId", report.tallyId),
        ]);
        setStumps(stumps);
        setProducts(products);
        const [productGroup, tally] = await Promise.all([
          fetchDocumentById<ProductGroup>(
            "productGroups",
            report.productGroupId,
          ),
          fetchDocumentById<Tally>("tallies", report.tallyId),
        ]);
        setProductGroup(productGroup);
        setTally(tally);
        setSummaryByStump(summarizeByStump(stumps, products));
      } catch (error) {
        console.error(error);
        navigate("/reports");
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  if (!by) {
    return null;
  }

  const totalValue = Object.values(summaryByStump).reduce(
    (acc, item) => acc + item.value,
    0,
  );

  const categorySummary = summarizeByCategory(
    summaryByStump,
    by as "species" | "product" | "stump",
  );

  return (
    <>
      <div className="h-full max-h-[50px] min-h-full w-full m-1 lg:m-0  ">
        <div className=" flex h-full w-full flex-col overflow-y-auto rounded-lg border border-gray-300 bg-white p-4 ">
          {/* header */}
          <BackNavigableResourceHeader
            title={`Report by ${by}`}
            onBackClick={() => navigate(`/reports/${reportId}`)}
          />
          <h1>Report Page: {by}</h1>
          <h2>Total Value: ${totalValue.toFixed(2)}</h2>
          <ul>
            {Object.entries(categorySummary).map(([key, value]) => (
              <li key={key}>
                {by === "species" && `Species ${key}: $${value.toFixed(2)}`}
                {by === "product" && `Product ${key}: $${value.toFixed(2)}`}
                {by === "stump" && `Stump ${key}: $${value.toFixed(2)}`}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
